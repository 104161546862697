import React from 'react';

const SplashPage = () => {
    return (
        <div className='splashpage'>
            <h1>Product Analytics Beyond Numbers.</h1>
            <h3>Profitable growth relies on understanding user behavior.
            Discover the why, not just the what. </h3>
        <p>
        Traditional analytics skim the surface, falling short of capturing the narrative 'why'. Event tracking quickly becomes cumbersome and hard to keep up with.
        </p>
        <p>
        Whether your growth is sales or product-led (PLG), use Teraace to accelerate activations, optimize onboarding, or target training.
        </p>
        <p>
         Effortless integration: three options to gather user behavior instantly
         <ul>
            <li>One line script auto-captures all user interactions</li>
            <li>Add Teraace as a destination in Segment</li>
            <li>Send events directly to Teraace's API from Mobile, App, or Web</li>
            </ul>   
        </p>
        <p>
        The data is processed, cleaned, and secured on Teraace's servers. Industry standard security practices have already been deployed.
        </p>
        <p>
        <img src="/splash/Teraace-Splash-Models.webp" alt="Happy Path page on Teraace" className="fullImage"></img>

        Transform complex user data into actionable insights with our Machine Learning algorithms. 
        Even with minimal datasets, unlock a comprehensive understanding of your users' behavior.
        </p>
        <p>
        The journey was theirs, the knowledge can be yours.
        Your map to success includes insights that are visual, interconnected, and ready for human consumption. 
        </p>
        <p>
        Don’t miss out on your chance for free access. Join the <a href="/betarequest">beta program</a>.
        </p>
        <hr></hr>
 
        <h2>Ways Teraace visualizes your data</h2>
        <p>
        <strong>Conversion Funnel:</strong> Ensure your pathway to revenue never leaks great users.
        <img src="/splash/Teraace-Splash-Funnel.gif" alt="Benchmark your goals"></img>
        </p>
        <p>
        <strong>Benchmarks:</strong> Aligned to your goals, monitor top-level KPIs to keep track on progress.
        <img src="/splash/Teraace-Splash-Benchmarks.gif" alt="Benchmark your goals"></img>
        </p>
        <p>
        <strong>Happy Path:</strong> Identify the most efficient and most traversed paths taken by users to achieve desired outcomes.
        <img src="/splash/Teraace-Splash-Happy_Path.webp" alt="Happy Path most effecient pathway to outcome"></img>
        </p>
        <p>
        <strong>Conversations:</strong> Chat with your data to learn precisely what drives a specific behavior.
        <img src="/splash/Teraace-Splash-Conversations.gif" alt="Conversations chat with your data"></img>
        </p>
        <p>
        <strong>Tree Explorer:</strong> Visualize user journeys in intricate detail, allowing for deep dives into decision-making processes.
        <img src="/splash/Teraace-Splash-Tree_Explorer.webp" alt="Tree Explorer control the journey"></img>
        </p>
        <p>
        <strong>Path Analyzer:</strong> Navigate the complex web of user behaviors with freedom, uncovering the myriad pathways that lead to conversion or drop-off.
        <img src="/splash/Teraace-Splash-Path_Analyzer.gif" alt="Path Analyzer navigate the web"></img>
        </p>
        <p>
        <strong>Journey Flow:</strong> Design and analyze bespoke user flows to assess the probability of achieving desired outcomes.
        <img src="/splash/Teraace-Splash-Journey_Flow.webp" alt="Journey Flow analyze probability"></img>
        </p>
        <p>
        <strong>Feature Insights:</strong> Gain granular insights into how users interact with specific pages and their subsequent navigation paths.
        <img src="/splash/Teraace-Splash-Feature_Insights.webp" alt="Feature Insights granular destinations"></img>
        </p>
<hr></hr>
        <h2>Teraace provides insights for every team</h2>
<p><ul>
Executives:
    <li>Empower leaders to drive decisions with a holistic view of product engagement</li>
    <li>Ensure decisions are aligned with the latest user interaction trends</li>

    Growth Teams:
   <li>Identify key factors driving user engagement and retention</li>
   <li>Craft strategies that promote long-term, sustainable growth</li>

   Product Management:
   <li>Shape your feature development roadmap with deep insights into user behavior</li>
   <li>Gauge the impact of new launches with precision, both before and after release</li>

   User Experience (UX) Design:
   <li>Design with empathy, utilizing narratives derived from actual user interactions</li>
   <li>Enhance usability through targeted design improvements informed by real users</li>
   
    Customer Success:
   <li>Pinpoint critical touchpoints along the customer journey to activation and adoption</li>
   <li>Develop training programs that build loyalty and demonstrate a commitment to customer needs</li>
       
    Product Marketing:
    <li>Develop marketing campaigns that truly resonate, informed by detailed analytics on user behavior</li>
    <li>Go beyond basic metrics to optimize for engagement that reflects genuine interest</li>
 
</ul>
</p>
<hr></hr>
<h2>Unlock the story of your users</h2>
<p>Join our <a href="/betarequest">beta program</a> today for free access.</p>

<hr></hr>
<h2>Don't see everything you need?</h2>
<p>Your feedback is invaluable to us. We're continually evolving and deploying new features rapidly based on our users' needs and suggestions.</p>
<p>
    &nbsp;
</p>
<p>
    &nbsp;
</p>
<p>
    ©2024 HYE Partners, LLC.
</p>
        </div>
    );
};

export default SplashPage;
