import React, { useState, useContext, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SEO from '../components/SEO';
import { DimensionContext } from '../components/ResponsiveWrapper';
import SanitizedInput from '../components/SanitizedInput';
import SanitizedText from '../components/SanitizedText';
import axios from 'axios';
import { NativeSelect } from '@mantine/core';

const BetaRequest = () => {
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const dimensions = useContext(DimensionContext);
    // console.log('dimensions', dimensions);
    const [page, setPage] = useState(1);
    const [formData, setFormData] = useState({
    companyName: '',
    appUrl: '',
    monthlyActiveUsers: '',
    yourName: '',
    yourEmail: '',
    howHear: '',
    integrate: '',
  });
  const [formValid, setFormValid] = useState({
    companyName: false,
    appUrl: false,
    monthlyActiveUsers: false,
    yourName: false,
    yourEmail: false,
    howHear: false,
    integrate: true,
  });

  const validateInput = (name, value) => {
    // console.log(`Validating ${name}: ${value}`);
    switch (name) {
      case 'yourEmail':
        return value.includes('@');
      case 'monthlyActiveUsers':
        return /^\d{2,}$/.test(value); // Regex to check for at least 2 digits.
      default:
        return value.length >= 3;
    }
  };

  const inputClassName = (name, isValid) => {
    let baseClass = 'form-input';
    let errorClass = !isValid && formData[name] !== '' ? 'invalid-input' : '';
    return `${baseClass} ${errorClass}`;
  };

  const handleInputChange = (e) => {
    // More robust handling to accommodate both standard events and custom objects
    let name, value;
  
    if (e.target) { // Handling standard events
      name = e.target.name;
      value = e.target.value;
    } else if (e.name && e.value !== undefined) { // Handling custom objects
      name = e.name;
      value = e.value;
    } else {
      console.error("handleInputChange was called with an unexpected object:", e);
      return; // Exit the function if the event object is not in the expected format
    }
  
    const isValid = validateInput(name, value);
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  
    setFormValid((prevFormValid) => ({
      ...prevFormValid,
      [name]: isValid,
    }));
  
    // console.log(`Form formData ${formData}:`, formData);
    // console.log(`Form formValid ${formValid}:`, formValid);
  };
  
  const handleSubmit = async () => {
    // Log the start of the submission process
    console.log("Starting form submission...");
  
    // Retrieve the authentication token from localStorage
    // const token = localStorage.getItem('authToken');
    // console.log("Retrieved auth token:", token);
  
    // Example HTML email body
    const emailBody = `
      <h1>Beta Request Submission</h1>
      <p><strong>Company Name:</strong> ${formData.companyName}</p>
      <p><strong>Application URL:</strong> ${formData.appUrl}</p>
      <p><strong>Monthly Active Users:</strong> ${formData.monthlyActiveUsers}</p>
      <p><strong>Your Name:</strong> ${formData.yourName}</p>
      <p><strong>Your Email:</strong> ${formData.yourEmail}</p>
      <p><strong>How did you hear about us?:</strong> ${formData.howHear}</p>
      <p><strong>How will you integrate?</strong> ${formData.integrate}</p>
    `;
    // Log the constructed email body
    console.log("Constructed email body:", emailBody);
  
    // Prepare the payload
    const payload = {
      to: "cheerful@outlook.com",
      subject: "Beta Request",
      htmlContent: emailBody,
    };
    // Log the payload
    // console.log("Payload to send:", payload);
  
    // Configure the request with the auth token
    const config = {
      headers: {'Content-Type': 'application/json'},
      withCredentials: true,
    };
    // Log the request configuration
    // console.log("Request config:", config);
  
    // Determine the base URL for your Go server
    const GO_BASE = process.env.REACT_APP_GO_BASE;
  
    try {
      // Make the POST request using Axios
      // console.log(`Sending request to ${GO_BASE}/email`);
      const response = await axios.post(`${GO_BASE}/email`, payload, config);
    
      // Log the successful response
      console.log("Beta Request Successful", response.data);
      setSubmissionSuccess(true); // Set success state
    } catch (error) {
      // Log detailed error information
      // console.error("Error sending email", error);
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response was received", error.request);
      } else {
        console.error("Error setting up the request", error.message);
      }
      // console.error("Config:", error.config);
    }
  };

  const pageVariants = {
    initial: { opacity: 0, x: '-100vw' },
    in: { opacity: 1, x: 0 },
    out: { opacity: 0, x: '100vw' }
  };

  const isFormValidForPage = (pageNum) => {
    // console.log(`Validating page ${pageNum}`);
    if (pageNum === 1) {
      return (
        validateInput('companyName', formData.companyName) &&
        validateInput('appUrl', formData.appUrl) &&
        validateInput('monthlyActiveUsers', formData.monthlyActiveUsers)
      );
    } else if (pageNum === 2) {
      return (
        validateInput('yourName', formData.yourName) &&
        validateInput('yourEmail', formData.yourEmail) &&
        validateInput('howHear', formData.howHear) &&
        validateInput('integrate', formData.integrate)
      );
    }
  };

  useEffect(() => {
    let timer;
    if (submissionSuccess) {
      // Show success message for 3 seconds before redirecting
      timer = setTimeout(() => {
        window.location.href = '/info'; // Redirect to the informational page
      }, 3000);
    }
    return () => clearTimeout(timer); // Cleanup the timer
  }, [submissionSuccess]); // Effect runs when submissionSuccess changes


  const renderPageContent = () => {
    switch (page) {
      case 1:
        return (
          <motion.div className="beta-request-content" initial="initial" animate="in" exit="out" variants={pageVariants}>
            <label className="form-label">Company Name</label>
            <SanitizedInput type="text" className={inputClassName('companyName', formValid.companyName)} name="companyName" value={formData.companyName} onChange={handleInputChange} />
            <label className="form-label">App URL</label>
            <SanitizedInput type="text" className={inputClassName('appUrl', formValid.appUrl)} name="appUrl" value={formData.appUrl} onChange={handleInputChange} />
            <label className="form-label">Monthly Active Users</label>
            <SanitizedInput type="text" className={inputClassName('monthlyActiveUsers', formValid.monthlyActiveUsers)} name="monthlyActiveUsers" value={formData.monthlyActiveUsers} onChange={handleInputChange} />
            <button className="form-button" onClick={() => setPage(2)} disabled={!isFormValidForPage(1)}>Next</button>
          </motion.div>
        );
      case 2:
        return (
          <motion.div className="beta-request-content" initial="initial" animate="in" exit="out" variants={pageVariants}>
            <label className="form-label">Your Name</label>
            <SanitizedInput className={inputClassName('yourName', formValid.yourName)} type="text" name="yourName" value={formData.yourName} onChange={handleInputChange} />
            <label className="form-label">Your Email</label>
            <SanitizedInput className={inputClassName('yourEmail', formValid.yourEmail)} type="text" name="yourEmail" value={formData.yourEmail} onChange={handleInputChange} />
            <label className="form-label">How did you hear about Teraace?</label>
            <SanitizedInput className={inputClassName('howHear', formValid.howHear)} type="text" name="howHear" value={formData.howHear} onChange={handleInputChange} />
            <NativeSelect
                className={inputClassName('integrate', formValid.integrate)}
                label="Integration Method"
                description="How will you send data to Teraace?"
                data={['Please Choose','One-line Teraace Script', 'Segment Destination', 'Send via API']}
                onChange={(event) => handleInputChange({
                  name: 'integrate',
                  value: event.currentTarget.value,
                })}
              />
            {/* <NativeSelect  className={inputClassName('integrate', formValid.integrate)} label="Integration Method" description="How will you send data to Teraace?" data={['Please Choose','One-line Teraace Script', 'Segment Destination', 'Send via API']} /> */}
            <button className="form-button" onClick={handleSubmit} disabled={!isFormValidForPage(2)}>Submit</button>
            {submissionSuccess && <div className="alert-text"><br/><br/>Your beta request has been received! We'll be in touch shortly to configure your account.</div>}
          </motion.div>
        );
      default:
        return null;
    }
  };

  // const renderPageContent = () => {
  //   switch (page) {
  //     case 1:
  //       return (
  //         <motion.div className="beta-request-content" initial="initial" animate="in" exit="out" variants={pageVariants}>
  //           <label className="form-label">Company Name</label>
  //           <SanitizedInput type="text" className="form-input" name="companyName" value={formData.companyName} onChange={handleInputChange} />
  //           <label className="form-label">App URL</label>
  //           <SanitizedInput type="text" name="appUrl" className="form-input" value={formData.appUrl} onChange={handleInputChange} />
  //           <label className="form-label">Monthly Active Users</label>
  //           <SanitizedInput type="text"  className="form-input"name="monthlyActiveUsers" value={formData.monthlyActiveUsers} onChange={handleInputChange} />
  //           <button className="form-button" onClick={() => setPage(2)} disabled={!isFormValidForPage(1)}>Next</button>
  //         </motion.div>
  //       );
  //     case 2:
  //       return (
  //         <motion.div className="beta-request-content" initial="initial" animate="in" exit="out" variants={pageVariants}>
  //           <label className="form-label">Your Name</label>
  //           <SanitizedInput className="form-input"type="text" name="yourName" value={formData.yourName} onChange={handleInputChange} />
  //           <label className="form-label">Your Email</label>
  //           <SanitizedInput className="form-input" type="text" name="yourEmail" value={formData.yourEmail} onChange={handleInputChange} />
  //           <label className="form-label">How did you hear about Teraace?</label>
  //           <SanitizedInput className="form-input" type="text" name="howHear" value={formData.howHear} onChange={handleInputChange} />
  //           <button className="form-button" onClick={handleSubmit} disabled={!isFormValidForPage(2)}>Submit</button>
  //         </motion.div>
  //       );
  //     default:
  //       return null;
  //   }
  // };

  return (
    <>
    <AnimatePresence>
        <div className="beta-request">
        <h1 className="h1 banner">Create Account</h1>

      {renderPageContent()}
      </div>
    </AnimatePresence>
    <div>
    <SEO
      title="Create new Teraace Account"
      description="Requests the creation of a Teraace account for beta access"
      url="/createaccount"
    />
    </div>
    </>
  );
};

export default BetaRequest;
