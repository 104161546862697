import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

const Sidebar = ({ pages, orgs, activeOrgID, handleOrgChange, onNavigate }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true); // State to manage login status
  const navigate = useNavigate(); // Hook to manage redirection
  // console.log("Sidebar: orgs:", orgs, "activeOrgID:", activeOrgID);

  const handleLogin = () => {
    navigate('https://app.teraace.com/auth'); // Redirect to /auth
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar-logo">
        <img src="/logo-Teraace-250.png" alt="logo"></img>
      </div>
    <div className="sidebar-link-container">
      {pages
          .filter(page => page.route !== "/auth")
          .map((page, index) => (
        <NavLink 
          key={index}
          to={page.route}
          className="sidebar-link"
          activeClassName="filter-active"
          onClick={() => onNavigate(page.route.replace('/', ''))}
        >
          {page.name}
        </NavLink>
      ))}
      {/* <button className="auth-logout-btn" onClick={handleLogin}>Login</button> */}
      </div>
    </div>
  );
};

export default Sidebar;
