import React from 'react';
import ReactDOM from 'react-dom';
import '@mantine/core/styles.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals(console.log);

// window.addEventListener('beforeunload', function() {
//     for (let key in localStorage) {
//         if (key !== 'authToken') {
//             localStorage.removeItem(key);
//         }
//     }
    
// });